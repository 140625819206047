import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getProjectTdsContractTerms } from '.';

export default function useGetProjectSupplierContractTerms(id) {
  return useQuery({
    queryKey: ['projectSupplierContractTerm', id],
    queryFn: () => getProjectTdsContractTerms(id),
    enabled: !!id
  });
}

export function useInvalidateGetProjectSupplierContractTerms(id) {
  const queryClient = useQueryClient();
  return useCallback(() =>
    queryClient.invalidateQueries(['projectSupplierContractTerm', id])
  );
}
