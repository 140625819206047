import React from 'react';

export default function StickyHeader({ children, height, style }) {
  const headerStyle = {
    position: 'sticky',
    top: '0px',
    background: '#ffffff',
    height,
    padding: '16px',
    borderBottom: '1px solid #dfe3ed',
    zIndex: 2000
  };
  return (
    <div className="sticky-header" style={{ ...headerStyle, ...style }}>
      {children}
    </div>
  );
}
