import { DownloadOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Row, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as EmptyState } from 'src/components/icons/svg/EmptyState.svg';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import StatusPill from 'src/components/project/procure/StatusPill';
import useGetProjectComplianceRequirement from 'src/Query/useGetProjectComplianceRequirement';
import styles from './CompliancePanel.module.less';

export default function CompliancePanel({ project }) {
  const { data: existingCompliances, isLoading } =
    useGetProjectComplianceRequirement({
      project: project.uuid,
      all: true
    });

  return (
    <Spin spinning={isLoading}>
      <ExplorePanel
        header={
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Text className="lg heavy">Compliance</Typography.Text>
            </Col>
            <Col>
              <StatusPill done={!!existingCompliances}>
                {existingCompliances ? 'Complete' : 'Pending'}
              </StatusPill>
            </Col>
          </Row>
        }
        pad
      >
        {!existingCompliances ? (
          <EmptyCompliance />
        ) : (
          existingCompliances.map((compliance) => (
            <Flex
              justify="space-between"
              align="center"
              className="mt"
              key={compliance.uuid}
            >
              <Flex className="gap-xs">
                <FileTextOutlined className={styles.textIcon} />
                <Typography.Text className={styles.textIcon}>
                  {compliance.documents ? compliance.documents[0].title : ''}
                </Typography.Text>
              </Flex>

              <Button icon={<DownloadOutlined />}>
                <a
                  target="_blank"
                  download={
                    compliance.documents ? compliance.documents[0].url : ''
                  }
                  href={compliance.document_pack}
                  className={styles.buttonText}
                >
                  Download
                </a>
              </Button>
            </Flex>
          ))
        )}
      </ExplorePanel>
    </Spin>
  );
}

CompliancePanel.propTypes = {
  project: PropTypes.object
};

function EmptyCompliance() {
  return (
    <Flex vertical justify="center" align="center" style={{ margin: 40 }}>
      <EmptyState style={{ height: 85 }} />
      <Typography.Text className="heavy mb-sm">
        Compliance pending
      </Typography.Text>
      <Button size="small" type="primary">
        Upload compliance files
      </Button>
    </Flex>
  );
}
