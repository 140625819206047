import React from 'react';
import { ReactComponent as Icon } from './svg/sample-tests.svg';
import SvgIconComponent from './SvgIconComponent';

function SampleTestsIcon({ ...props }) {
  return (
    <SvgIconComponent {...props}>
      <Icon />
    </SvgIconComponent>
  );
}

export default SampleTestsIcon;
