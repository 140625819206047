import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';
import { createOrUpdateCompliance } from 'src/Mutation';

export default function useUploadCompliance(onSuccess) {
  const [messageApi, contextHolder] = message.useMessage();

  const mutation = useMutation({
    mutationFn: (body) => createOrUpdateCompliance(body),
    onSuccess: () => {
      messageApi.open({
        type: 'success',
        content: 'Project compliance documents uploaded successfully.'
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (e) => {
      messageApi.open({
        type: 'error',
        content: 'An error occurred. Please try again.'
      });
    }
  });

  return {
    ...mutation,
    messageContext: contextHolder
  };
}
