import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LockFilled } from '@ant-design/icons';
import { getNextStep } from 'src/components/project/source/helpers';
import { useSidebarContext } from './SidebarContext';
import SidebarCascade from './SidebarCascade';

export default function ProjectSourceCascade() {
  const { projects, clearCascades, getProjectbyId, setActiveProjectId } =
    useSidebarContext();
  const sourceProjects = useMemo(
    () =>
      (projects || [])
        .filter((p) => ['search', 'procure', 'manage'].includes(p.stage))
        .map((p) => ({
          key: p.uuid,
          uuid: p.uuid,
          name: p.name || 'Project',
          icon: p.stage !== 'search' ? <LockFilled /> : undefined
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [projects]
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onProjectSelectItem = (p) => {
    const project = getProjectbyId(p.key);
    const nextStep = getNextStep(project);
    setActiveProjectId(p.key);
    navigate(`/project/source/${p.key}/${nextStep.key}`);

    clearCascades();
  };

  const cascadeItems = useMemo(
    () =>
      sourceProjects.map((project) => ({
        label: project.name,
        key: project.uuid || 'playground',
        active: pathname.includes(project.uuid || 'playground'),
        icon: project.icon,
        project,
        next: true
      })),
    [sourceProjects]
  );

  return (
    <SidebarCascade
      key="source"
      title="Source"
      items={cascadeItems}
      selectItem={onProjectSelectItem}
      allowSearch
    />
  );
}
