import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getProjectContractTerms } from '.';

export default function useGetProjectContractTerms(id) {
  return useQuery({
    queryKey: ['projectContractTerm', id],
    queryFn: () => getProjectContractTerms(id),
    enabled: !!id
  });
}

export function useInvalidateGetProjectContractTerms(id) {
  const queryClient = useQueryClient();
  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ['projectContractTerm', id]
      }),
    [id]
  );
}
