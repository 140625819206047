import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from 'antd';
import styles from './BuyerTestingSetupLayout.module.less';

export default function BuyerTestingSetupLayout({ children, ...props }) {
  return (
    <Flex align="center" justify="center" {...props}>
      <div className={styles.buyerContent}>{children}</div>
    </Flex>
  );
}

BuyerTestingSetupLayout.propTypes = {
  children: PropTypes.node
};
