import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Typography, Form, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@tanstack/react-query';

import { createOrUpdateTdsTest } from 'src/Mutation';
import { getTDSTests } from 'src/Query';
import QuantityWithUnitsInput from 'src/components/form/QuantityWithUnitsInput';
import { ALL_PRIMARY_KPI_KEYS } from 'src/components/project/hooks/usePrimaryKpis';
import useRenderKpiRow from 'src/hooks/project/useRenderKpiRow';
import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import SteppedPerSupplierForm from 'src/pages/project/components/buyer-testing-setup/SteppedPerSupplierForm';

import styles from './TestingStep.module.less';

const findTestForProjectTds = (existingTests, projectTds) =>
  existingTests?.find((test) =>
    test?.project_tdss?.includes(projectTds?.project_tds_id)
  );

export default function TestingStep({ project }) {
  const complete = useTestingStepComplete(project);

  const [form] = Form.useForm();
  const { renderKpiRow, mappedKpiCodeMetadata } = useRenderKpiRow('uuid');

  const { data: existingTests } = useQuery(['tds-tests', project.uuid], () =>
    getTDSTests({ project_tdss__project: project.uuid, all: true })
  );

  const { mutateAsync: createorUpdateTdsTestData } = useMutation({
    mutationFn: (data) => createOrUpdateTdsTest(data)
  });

  const [projectTds, setProjectTds] = useState();
  const existingProjectTdsTest = useMemo(
    () => findTestForProjectTds(existingTests, projectTds),
    [existingTests, projectTds]
  );

  // When existing test loads, update form
  useEffect(() => {
    if (existingProjectTdsTest) {
      const formattedNumericalPropertyTests =
        existingProjectTdsTest.numerical_property_tests.reduce(
          (agg, testData) => ({
            ...agg,
            [testData.property.code]: {
              ...testData,
              min: testData.min_expected,
              max: testData.max_expected,
              methods: testData.test_standards
            }
          }),
          {}
        );
      form.setFieldsValue({
        ...existingProjectTdsTest,
        digital_rfq_filter: {
          kpis: {
            ...formattedNumericalPropertyTests
          }
        }
      });
    }
  }, [existingProjectTdsTest, form]);

  const kpiKeys = Object.keys(
    (project?.digital_rfq_filter ?? project?.filters)?.kpis ?? {}
  );

  const secondaryKpis = kpiKeys
    .filter((kpi) => !ALL_PRIMARY_KPI_KEYS.includes(kpi))
    .sort((a, b) => a.localeCompare(b));

  const primaryKpis = kpiKeys
    .filter((kpi) => ALL_PRIMARY_KPI_KEYS.includes(kpi))
    .sort((a, b) => a.localeCompare(b));

  const handleClickNext = useMemo(
    () => async () => {
      const values = await form.validateFields();

      const currentKpis = Object.keys(values?.digital_rfq_filter?.kpis ?? {});
      const numericalPropertyTests = currentKpis.map((kpiCode) => {
        const kpiData = values?.digital_rfq_filter?.kpis[kpiCode];
        const kpiMetadata = mappedKpiCodeMetadata.get(kpiCode);
        return {
          ...kpiData,
          property_id: kpiMetadata.uuid,
          min_expected: kpiData.min ?? null,
          max_expected: kpiData.max ?? null,
          units: kpiData.units ?? null,
          test_standards: kpiData.methods ?? null
        };
      });

      const updatedTestingData = {
        uuid: existingProjectTdsTest?.uuid,
        tds: projectTds.tds_id,
        project_tds_id: projectTds.project_tds_id,
        volume: values.volume,
        volume_units: values.volume_units,
        numerical_property_tests: numericalPropertyTests
      };

      return createorUpdateTdsTestData(updatedTestingData);
    },
    [projectTds, form, mappedKpiCodeMetadata, existingProjectTdsTest]
  );

  const digitalRfqDefaultValues = useMemo(
    () => project?.digital_rfq_filter ?? {},
    [project]
  );

  return (
    <SteppedPerSupplierForm
      form={form}
      onNext={handleClickNext}
      onProjectTdsChange={setProjectTds}
      complete={complete}
    >
      <>
        <Typography.Title className="mt-lg" level={4}>
          Testing Volume
        </Typography.Title>
        <Form
          form={form}
          initialValues={{
            digital_rfq_filter: digitalRfqDefaultValues
          }}
          layout="vertical"
        >
          <Flex className="gap-lg mt-lg" align="center">
            <Form.Item
              rules={[{ required: true }]}
              name="volume"
              className="mb-0"
              label="Lab Test Volume"
            >
              <QuantityWithUnitsInput
                className={styles.quantityInput}
                unitsFieldName="volume_units"
              />
            </Form.Item>
          </Flex>
          {primaryKpis && primaryKpis.length ? (
            <>
              <Typography.Title level={5} className="mt-40">
                Primary Specifications
              </Typography.Title>
              <Typography.Text type="secondary">
                Imported from Explore
              </Typography.Text>
              <Row className="mt-lg">
                <Col span={9}>
                  <Typography className={styles.propertyTitle}>
                    Property
                  </Typography>
                </Col>
                <Col span={3}>
                  <Typography className={styles.propertyTitle}>Min.</Typography>
                </Col>
                <Col span={3}>
                  <Typography className={styles.propertyTitle}>Max.</Typography>
                </Col>
                <Col span={3}>
                  <Typography className={styles.propertyTitle}>
                    Units.
                  </Typography>
                </Col>
                <Col span={6}>
                  <Typography className={styles.propertyTitle}>
                    Testing Method
                  </Typography>
                </Col>
              </Row>
              {primaryKpis.map((kpiCode) => renderKpiRow(kpiCode))}
            </>
          ) : null}
          {secondaryKpis && secondaryKpis.length > 0 ? (
            <>
              <Typography.Title level={5} className="mt-40">
                Secondary Specifications
              </Typography.Title>
              <Typography.Text type="secondary">
                Imported from RFQ
              </Typography.Text>
              {secondaryKpis.map((kpiCode) => renderKpiRow(kpiCode, true))}
            </>
          ) : null}
        </Form>
      </>
    </SteppedPerSupplierForm>
  );
}
TestingStep.propTypes = {
  project: PropTypes.object
};

export function useTestingStepComplete(project) {
  const { data: { results: allProcureTdss } = {} } = useProcureTdss();
  const { data: existingTests } = useQuery(['tds-tests', project?.uuid], () =>
    getTDSTests({ project_tdss__project: project?.uuid, all: true })
  );

  return allProcureTdss?.every(
    (tds) => !!findTestForProjectTds(existingTests, tds)
  );
}
