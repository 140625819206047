import React, { useMemo, useState } from 'react';
import { Alert, Button, Form, Modal, Select, Space } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { patchUser } from 'src/Mutation';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { useAvailableUnitsForType } from 'src/utils/units';
import { useUser } from 'src/utils/authentication';
import { supportCurrencyCodes } from 'src/utils/currency';

const { useForm } = Form;

export default function useSettingsPopup(permissions) {
  const userIsConcierge = useIsConcierge();
  const { data: user } = useUser();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [form] = useForm();

  const weightUnits = useAvailableUnitsForType('weight');
  const weightOptions = useMemo(
    () =>
      weightUnits?.map((unit) => ({
        label: unit,
        value: unit
      })) || [],
    [weightUnits]
  );
  const currencyOptions = useMemo(
    () =>
      supportCurrencyCodes?.map((c) => ({
        label: c,
        value: c
      })) || [],
    [weightUnits]
  );

  const { mutate: updateUser } = useMutation({
    mutationFn: patchUser,
    onError: (e) => {
      setError('Error updating user');
    },
    onSuccess: () => setOpen(false)
  });

  if (!userIsConcierge) {
    return {};
  }

  const submit = async ({ weight, currency }) => {
    updateUser({
      id: user?.id,
      settings: {
        preferred_currency: currency,
        unit_preferences: {
          ...user?.settings?.unit_preferences,
          weight
        }
      }
    });
  };

  const ModalComponent = (
    <Modal
      className="settings-modal"
      width={600}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      {error && <Alert message={error} type="error" closable showIcon />}
      <Form
        name="settings"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={submit}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Weight"
          name="weight"
          initialValue={user?.settings?.unit_preferences?.weight || 'lb'}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select options={weightOptions} />
        </Form.Item>
        <Form.Item
          label="Currency"
          name="currency"
          initialValue={
            user?.settings?.preferred_currency ||
            user?.company?.preferred_currency ||
            'USD'
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select options={currencyOptions} />
        </Form.Item>
        <Space direction="horizontal">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button type="default" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );

  return {
    ModalComponent,
    openModal: () => setOpen(true)
  };
}
