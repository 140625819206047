import React from 'react';
import {
  Flex,
  Typography,
  Form,
  Col,
  InputNumber,
  Select,
  Switch,
  Row,
  Input
} from 'antd';
import { numberInputFormatter } from 'src/components/project/explore/filters/KpiFilter';
import UnitsSelect from 'src/components/form/UnitsSelect';
import classNames from 'classnames';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import { groupBy, uniqBy } from 'lodash';
import useMetaNumericalPropertyTestingMethods from 'src/hooks/useMetaNumericalPropertyTestingMethod';
import styles from './KpiRow.module.less';

/**
 * Custom hook to render KPI rows based on the provided testing method.
 *
 * This hook fetches metadata and testing methods related to numerical properties,
 * maps them by their codes and UUIDs, and groups the testing methods by KPI code.
 * It provides a function to render KPI rows with the appropriate metadata and testing method options.
 *
 * @param {string} testingMethod - The method to use for testing, either 'uuid' or 'code'. Default is 'code'.
 * @returns {function} renderKpiRow - Function to render KPI rows.
 * @returns {Map} mappedKpiCodeMetadata - Map of numerical property metadata by code.
 * @returns {Map} mappedKpiIdMetadata - Map of numerical property metadata by UUID.
 */

export default function useRenderKpiRow(testingMethod = 'code') {
  const testingMethods = useMetaNumericalPropertyTestingMethods();
  const metadata = useMetaNumericalProperty();

  const mappedKpiCodeMetadata = new Map(
    (metadata ?? []).map((item) => [item.code, item])
  );
  const mappedKpiIdMetadata = new Map(
    metadata.map((item) => [item.uuid, item])
  );

  const groupedTestingMethodsByKpiCode = groupBy(
    testingMethods
      .map((item) => ({
        value: testingMethod === 'code' ? item.code : item.uuid,
        label: item.name,
        kpi_code: item.meta_numerical_property?.code
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    'kpi_code'
  );

  const renderKpiRow = (kpiCode, isSecondarySpec = false) => {
    const kpiMetadata = mappedKpiCodeMetadata.get(kpiCode);
    const testingMethodOptions = uniqBy(
      groupedTestingMethodsByKpiCode[kpiCode],
      'value'
    );
    if (!kpiMetadata) return null;

    return (
      <Row key={kpiCode} className={styles.inputCard}>
        <Col span={9}>
          <Flex className="h-full" align="center">
            {isSecondarySpec ? (
              <Flex className="h-full gap-sm" align="center">
                <Form.Item
                  name={['digital_rfq_filter', 'kpis', kpiCode, 'isToggleOff']}
                  getValueProps={(value) => ({
                    value: !value
                  })}
                  getValueFromEvent={(checked) => !checked}
                  className="m-0 ml-sm"
                >
                  <Switch />
                </Form.Item>
                <Typography className={classNames(styles.primaryText, 'ml-sm')}>
                  {kpiMetadata.name}
                </Typography>
              </Flex>
            ) : (
              <Typography className={classNames(styles.primaryText, 'ml-sm')}>
                {kpiMetadata.name}
              </Typography>
            )}
          </Flex>
        </Col>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues?.digital_rfq_filter.kpis[kpiCode]?.isToggleOff !==
            currentValues?.digital_rfq_filter.kpis[kpiCode]?.isToggleOff
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const isToggleOff = getFieldValue([
              'digital_rfq_filter',
              'kpis',
              kpiCode,
              'isToggleOff'
            ]);
            return (
              <>
                <Col className="pr-sm" span={3}>
                  {/* Sometimes we are using this to update and don't want to lose uuid value */}
                  <Form.Item
                    noStyle
                    hidden
                    name={['digital_rfq_filter', 'kpis', kpiCode, 'uuid']}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className="m-0"
                    name={['digital_rfq_filter', 'kpis', kpiCode, 'min']}
                    rules={[
                      {
                        validator: (_, value) => {
                          const max = getFieldValue([
                            'digital_rfq_filter',
                            'kpis',
                            kpiCode,
                            'max'
                          ]);
                          if (value && max && value > max) {
                            return Promise.reject(
                              new Error('Min should be less than or equal Max')
                            );
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <InputNumber
                      min={0}
                      className={styles.inputNumber}
                      placeholder="No Min"
                      formatter={numberInputFormatter}
                      type="number"
                      disabled={isToggleOff}
                    />
                  </Form.Item>
                </Col>
                <Col className="pr-sm" span={3}>
                  <Form.Item
                    className="m-0"
                    name={['digital_rfq_filter', 'kpis', kpiCode, 'max']}
                    rules={[
                      {
                        validator: (_, value) => {
                          const min = getFieldValue([
                            'digital_rfq_filter',
                            'kpis',
                            kpiCode,
                            'min'
                          ]);
                          if (value && min && value < min) {
                            return Promise.reject(
                              new Error(
                                'Max should be greater than or equal Min'
                              )
                            );
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <InputNumber
                      min={0}
                      className={styles.inputNumber}
                      placeholder="No Max"
                      formatter={numberInputFormatter}
                      type="number"
                      disabled={isToggleOff}
                    />
                  </Form.Item>
                </Col>
                <Col className="pr-sm" span={3}>
                  <Form.Item
                    className="m-0"
                    name={['digital_rfq_filter', 'kpis', kpiCode, 'units']}
                  >
                    <UnitsSelect
                      unitType={kpiMetadata.unit_type}
                      disabled={isToggleOff}
                    />
                  </Form.Item>
                </Col>
                <Col className="pr-sm" span={6}>
                  <Form.Item
                    className="m-0"
                    name={['digital_rfq_filter', 'kpis', kpiCode, 'methods']}
                  >
                    <Select
                      options={testingMethodOptions}
                      allowClear
                      maxTagCount={1}
                      className={styles.selectTestingMethods}
                      disabled={isToggleOff}
                    />
                  </Form.Item>
                </Col>
              </>
            );
          }}
        </Form.Item>
      </Row>
    );
  };

  return {
    renderKpiRow,
    mappedKpiCodeMetadata,
    mappedKpiIdMetadata
  };
}
