import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getProjectTdsTest } from '.';

export default function useGetProjectTdsTest(id) {
  return useQuery({
    queryKey: ['projectTdsTest', id],
    queryFn: () => getProjectTdsTest(id),
    enabled: !!id
  });
}

export function useInvalidateGetProjectTdsTest(id) {
  const queryClient = useQueryClient();
  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ['projectTdsTest', id]
      }),
    [id]
  );
}
