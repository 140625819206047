import { useQuery } from '@tanstack/react-query';
import { getProjectComplianceRequirement } from '.';

export default function useGetProjectComplianceRequirement(payload) {
  return useQuery(
    ['project-compliance-requirement', payload.project],
    () => getProjectComplianceRequirement(payload),
    {
      enabled: !!payload.project
    }
  );
}
