import { Form, Select, InputNumber } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAvailableUnitsForType, usePreferredUnits } from 'src/utils/units';
import {
  formatterInputCurrency,
  parserInputCurrency
} from 'src/utils/currency';

export default function QuantityWithUnitsInput({
  unitsFieldName,
  per,
  value,
  ...props
}) {
  const weightUnits = useAvailableUnitsForType('weight');
  const weightOptions = useMemo(
    () =>
      weightUnits?.map((unit) => ({
        label: `${per ? '/' : ''}${unit}`,
        value: unit
      })) || [],
    [weightUnits, per]
  );
  const defaultUnits = usePreferredUnits('weight');
  const valueRounded = Number.isFinite(value)
    ? Math.round(value * 10000) / 10000
    : value;
  return (
    <InputNumber
      min={0}
      formatter={formatterInputCurrency}
      value={valueRounded}
      parser={parserInputCurrency}
      addonAfter={
        weightUnits && defaultUnits ? (
          <Form.Item
            initialValue={defaultUnits}
            name={unitsFieldName}
            rules={[{ required: true }]}
            noStyle
          >
            <Select style={{ width: 65 }} options={weightOptions} />
          </Form.Item>
        ) : (
          ''
        )
      }
      {...props}
    />
  );
}
QuantityWithUnitsInput.propTypes = {
  unitsFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.number,
  per: PropTypes.bool
};
