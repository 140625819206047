import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';

function NotFound({ title }) {
  return (
    <div className="error-not-found">
      <h3>{title}</h3>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="" />
    </div>
  );
}

NotFound.propTypes = {
  title: PropTypes.string
};

export default NotFound;
