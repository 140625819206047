import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, Typography } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import { getProjectComplianceRequirement } from 'src/Query';
import UploadDocuments from 'src/components/form/UploadDocuments';
import useUploadCompliance from 'src/Mutation/useUpdateProjectCompliance';
import FullScreenSteps from 'src/components/layout/FullScreenSteps';
import ComplianceIcon from 'src/components/icons/Compliance';
import CheckboxCard from 'src/components/utils/CheckboxCard';

import useGetProjectComplianceRequirement from 'src/Query/useGetProjectComplianceRequirement';
import BuyerTestingSetupLayout from './BuyerTestingSetupLayout';
import styles from './ComplianceStep.module.less';

export default function ComplianceStep({ project }) {
  const completed = useComplianceStepComplete(project);

  const [currentStep, setCurrentStep] = useState(completed ? 1 : 0);
  const [form] = Form.useForm();
  const uploadedDocuments = Form.useWatch('documents', form);

  const { data: existingCompliances } = useGetProjectComplianceRequirement({
    project: project.uuid,
    all: true
  });

  useEffect(() => {
    if (existingCompliances?.length) {
      form.setFieldsValue({
        documents: existingCompliances[0].documents
      });
    }
  }, [existingCompliances]);

  const {
    mutate: uploadComplianceDocuments,
    isLoading,
    messageContext
  } = useUploadCompliance(() => setCurrentStep(1));

  const handleClickNext = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    uploadComplianceDocuments({
      projectId: project.uuid,
      uuid: existingCompliances?.[0]?.uuid,
      documents_ids: values.documents.map((d) => d.uuid)
    });
  };

  return (
    <FullScreenSteps
      title={
        <>
          <ComplianceIcon style={{ verticalAlign: '-0.4em' }} />{' '}
          <Typography.Text className="heavy poppins font-size-4 ml-xs">
            Add Compliance Docs
          </Typography.Text>
        </>
      }
      steps={[{ title: 'Compliance Docs' }, { title: 'Complete' }]}
      currentStep={currentStep}
      rightContent={
        <>
          <Link to="..">
            <Button>Back to Overview</Button>
          </Link>
          {currentStep === 0 ? (
            <Button
              loading={isLoading}
              type="primary"
              onClick={handleClickNext}
              className="ml-xs"
            >
              Save Compliance Docs
            </Button>
          ) : null}
        </>
      }
    >
      <BuyerTestingSetupLayout>
        {messageContext}
        {currentStep === 0 ? (
          <>
            <Flex
              className="w-full mt-60"
              align="center"
              justify="space-between"
            >
              <div>
                <Typography.Title level={3}>
                  Compliance Documentation
                </Typography.Title>
                <Typography.Text type="secondary">
                  Send documents to suppliers to fill out before terms are
                  accepted
                </Typography.Text>
              </div>
            </Flex>
            <Form form={form} layout="vertical" className="mt-xxl">
              <Form.Item
                className="mb-0"
                name="documents"
                label="Upload Compliance Documents"
                rules={[
                  {
                    required: true,
                    message: 'Please upload at least one document.'
                  }
                ]}
              >
                <UploadDocuments maxCount={10} />
              </Form.Item>
            </Form>
            {uploadedDocuments && uploadedDocuments?.length > 0 ? (
              <Typography.Text className={styles.description} type="secondary">
                These documents will be sent to suppliers to fill out before
                terms are accepted.
              </Typography.Text>
            ) : null}
          </>
        ) : (
          <div style={{ maxWidth: '700px', margin: '0 auto' }}>
            <Typography.Title className="mb-xl" level={3}>
              Compliance Docs Added
            </Typography.Title>
            <CheckboxCard
              complete
              title="Compliance Documents"
              description={`${uploadedDocuments?.length} documents uploaded.`}
              buttonText="View"
              buttonAction={() => setCurrentStep(0)}
            />
          </div>
        )}
      </BuyerTestingSetupLayout>
    </FullScreenSteps>
  );
}
ComplianceStep.propTypes = {
  project: PropTypes.object
};

export function useComplianceStepComplete(project) {
  const { data: existingCompliances } = useQuery(
    ['project-compliance-requirement', project.uuid],
    () => getProjectComplianceRequirement({ project: project.uuid, all: true })
  );

  return existingCompliances?.length > 0;
}
