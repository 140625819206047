import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import React, { useMemo, useState } from 'react';
import {
  getMarketPriceTickers,
  getMarketPriceTickersWarehouse
} from 'src/Query/marketPrice';
import { useDebounce } from 'src/hooks/useDebounce';

function IndexSelect({ warehouse, filters, ...props }) {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const params = {
    search: debouncedSearchValue,
    all: true,
    ...filters
  };

  const { data: tickers, isLoading } = useQuery(['tickers', params], () =>
    warehouse
      ? getMarketPriceTickersWarehouse(params)
      : getMarketPriceTickers(params)
  );

  const options = useMemo(() =>
    tickers?.map((ticker) => ({
      value: ticker.uuid || ticker.market_price_ticker_id,
      label: `(${ticker.source} ${ticker.source_code}) ${ticker.source_name}`,
      tag: `(${ticker.source} ${ticker.source_code}) ${ticker.source_name}`
    }))
  );

  return (
    <Select
      loading={isLoading}
      options={options}
      filterOption={false}
      onSearch={(v) => setSearchValue(v)}
      showSearch
      {...props}
    />
  );
}

export default IndexSelect;
