import React, { useMemo } from 'react';
import { Typography } from 'antd';

import CheckboxCard from 'src/components/utils/CheckboxCard';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import useGetProjectTdsTest from 'src/Query/useGetProjectTdsTest';
import useProcureTdss from 'src/components/project/procure/useProcureTdss';
import { useNavigate } from 'react-router-dom';

const DEFAULT_RESULTS_LIST = [];
const DEFAULT_RESULTS = { results: DEFAULT_RESULTS_LIST };

export default function ProjectTdsTestOverview({ project }) {
  const navigate = useNavigate();
  const projectId = project?.uuid;
  const {
    data: { results: allProcureTdss } = DEFAULT_RESULTS,
    isLoading: isLoadingTdss
  } = useProcureTdss();
  const {
    data: projectTdsTest = DEFAULT_RESULTS_LIST,
    isLoading: isLoadingTdsTest
  } = useGetProjectTdsTest(projectId);

  // create a list of contract terms that correspond to each project TDS
  const projectTdsTestPerTds = useMemo(
    () =>
      allProcureTdss.map((tds) =>
        projectTdsTest.find((ct) => ct.tds.uuid === tds.tds_id)
      ),
    [allProcureTdss, projectTdsTest]
  );

  const isLoading = isLoadingTdsTest || isLoadingTdss;

  const loadingClass = useLoadingStateClass(isLoading);
  const navigateToSupplier = (i) => {
    navigate(
      `/project/procure/${projectId}/testing/${allProcureTdss[i].project_tds_id}`
    );
  };
  return (
    <div
      className={`mt-xl ${loadingClass}`}
      style={{ maxWidth: '700px', margin: '0 auto' }}
    >
      <Typography.Title className="mb-xl" level={3}>
        Testing Details
      </Typography.Title>
      {allProcureTdss.map(({ title, company_name: supplierName }, i) => (
        <CheckboxCard
          key={i}
          complete={!!projectTdsTestPerTds[i]}
          title={supplierName}
          description={
            projectTdsTestPerTds[i]
              ? 'Specifications and testing conditions confrimed.'
              : 'Please add specifications and testing conditions.'
          }
          buttonText={projectTdsTestPerTds[i] ? 'Edit' : 'Add'}
          buttonAction={() => navigateToSupplier(i)}
        />
      ))}
    </div>
  );
}
