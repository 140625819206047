import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Steps, Col } from 'antd';
import FullScreenModal from 'src/components/layout/FullScreenModal';

export default function FullScreenSteps({
  title,
  steps,
  currentStep,
  rightContent,
  children
}) {
  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef?.current?.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [currentStep]);
  return (
    <FullScreenModal
      open
      stickyHeader
      className="full-screen-steps"
      headerContent={
        <Row className="px-md py-sm" align="middle">
          <Col span="6">{title}</Col>
          <Col span="12">
            <Steps progressDot items={steps} current={currentStep} />
          </Col>
          <Col span="6">{rightContent}</Col>
        </Row>
      }
      ref={scrollRef}
    >
      {children}
    </FullScreenModal>
  );
}
FullScreenSteps.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  rightContent: PropTypes.node,
  children: PropTypes.node
};
