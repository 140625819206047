import { useMemo } from 'react';
import useIsBalesMode from 'src/components/project/explore/hooks/useIsBalesMode';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import { useSecondaryKpis } from 'src/components/project/hooks/useSecondaryKpis';

const DEFAULT_VALUE = {};

const nullifyIfIdenticalFilters = (objToNullify, objToCompareAgainst) => {
  if (JSON.stringify(objToNullify) === JSON.stringify(objToCompareAgainst))
    return null;

  return objToNullify;
};

const filtersAreEqual = (objToNullify, objToCompareAgainst) =>
  objToNullify &&
  objToCompareAgainst &&
  JSON.stringify(objToNullify) === JSON.stringify(objToCompareAgainst);

const removeDuplicateFilters = (list) => {
  list.forEach((filter, index) => {
    if (
      list.slice(0, index).some((ancestor) => filtersAreEqual(filter, ancestor))
    ) {
      list[index] = null; // eslint-disable-line no-param-reassign
    }
  });
  return list;
};

/* eslint-disable no-param-reassign */
export default function useExpandedFiltersForSupplierList(expanded) {
  const isBales = useIsBalesMode();

  const balesFilters = useExpandedBalesFilters(expanded);
  const resinFilters = useExpandedResinFilters(expanded);
  if (isBales) return balesFilters;

  return resinFilters;
}

function useExpandedResinFilters(expanded) {
  const filters = useRelevantFiltersForForm(expanded) || DEFAULT_VALUE;
  const kpiFilters = filters.kpis || DEFAULT_VALUE;

  const propertyFilters = filters.properties || DEFAULT_VALUE;
  const hasPropertyFilters = !!Object.keys(propertyFilters).length;

  const kpiFiltersCodes = Object.keys(kpiFilters);
  const hasKpiFilters = !!kpiFiltersCodes.length;
  const secondaryKpis = useSecondaryKpis();

  // 0 Best - all primary and 2+ secondary
  const allPrimaryAndSecondarySpecs = useFiltersExpansion(filters, {
    primary: 'all',
    secondary: '2'
  });

  // 1 2nd best - all primary, one secondary
  const allPrimarySpecsAnySecondarySpecs = useFiltersExpansion(filters, {
    primary: 'all',
    secondary: '1'
  });

  // 2 3rd - all primary, secondaries not taken into account
  const allPrimarySpecs = useFiltersExpansion(filters, {
    primary: 'all',
    secondary: '0'
  });

  // 3 3rd - all primary, secondaries not taken into account
  const anyPrimarySpecAllSecondarySpec = useFiltersExpansion(filters, {
    primary: '1',
    secondary: '2'
  });

  // 4 - 1+ primary, 1+ secondary
  const anyPrimarySpecAnySecondarySpec = useFiltersExpansion(filters, {
    primary: '1',
    secondary: '1'
  });

  // 5 - 1+ primary, secondaries not taken into account
  const anyPrimarySpec = useFiltersExpansion(filters, {
    primary: '1',
    secondary: '0'
  });

  // 6 - 1+ secondary, primaries not taken into account
  const allSecondaryNoPrimary = useFiltersExpansion(filters, {
    primary: '0',
    secondary: '2'
  });

  // 7 - 1+ secondary, primaries not taken into account
  const anyProperty = useFiltersExpansion(filters, {
    primary: undefined,
    secondary: '1'
  });

  if (!hasKpiFilters && !hasPropertyFilters) {
    // We need to default to original filters,
    // otherwise no filtering will be applied at all
    return {
      filterList: [filters],
      resultDetails: [
        {
          primary: 'none',
          secondary: 'none'
        }
      ]
    };
  }

  return {
    filterList: [
      allPrimaryAndSecondarySpecs,
      allPrimarySpecsAnySecondarySpecs,
      allPrimarySpecs,
      anyPrimarySpecAllSecondarySpec,
      anyPrimarySpecAnySecondarySpec,
      anyPrimarySpec,
      secondaryKpis.length ? allSecondaryNoPrimary : null,
      anyProperty
    ],
    resultDetails: [
      {
        primary: 'full',
        secondary: 'full',
        verified: true,
        expanded
      },
      {
        primary: 'full',
        secondary: 'partial',
        verified: true,
        expanded
      },
      {
        primary: 'full',
        secondary: 'none',
        verified: true,
        expanded
      },
      {
        primary: 'partial',
        secondary: 'full',
        verified: true,
        expanded
      },
      {
        primary: 'partial',
        secondary: 'partial',
        verified: true,
        expanded
      },
      {
        primary: 'partial',
        secondary: 'none',
        verified: true,
        expanded
      },
      {
        primary: 'none',
        secondary: 'full',
        verified: true,
        expanded
      },
      {
        primary: 'none',
        secondary: 'partial',
        verified: true,
        expanded
      }
    ]
  };
}

const interleaveArrays = (array1, array2) =>
  (array1 || []).flatMap((item, index) => [item, (array2 || [])[index]]);

export function useExpandedFiltersForSupplierListWithExpanded() {
  const regularFilters = useExpandedFiltersForSupplierList();
  const expandedFilters = useExpandedFiltersForSupplierList(true);

  const isBales = useIsBalesMode();
  if (isBales) return regularFilters;

  const unverified = {
    ...(regularFilters.filterList[0] || {}),
    properties: undefined,
    kpis: undefined,
    capacity__gte: undefined,
    kpis_properties: { kpis: {}, properties: {} }
  };

  const validExpanded =
    regularFilters.filterList.length === expandedFilters.filterList.length;
  const expandedFilterList = validExpanded
    ? expandedFilters.filterList
    : new Array(regularFilters.filterList.length).fill(null);
  const expandedResultDetails = validExpanded
    ? expandedFilters.resultDetails
    : new Array(regularFilters.filterList.length).fill(null);
  const result = {
    filterList: [
      ...removeDuplicateFilters(
        interleaveArrays(regularFilters.filterList, expandedFilterList)
      ),
      unverified
    ],
    resultDetails: [
      ...interleaveArrays(regularFilters.resultDetails, expandedResultDetails),
      {
        primary: 'none',
        secondary: 'none',
        verified: false,
        expanded: false
      }
    ]
  };
  return result;
}

function useExpandedBalesFilters(expanded) {
  const filters = useRelevantFiltersForForm(expanded) || DEFAULT_VALUE;
  const allSecondary = useFiltersExpansion(
    filters,
    {
      primary: '0',
      secondary: '3'
    },
    true
  );
  const someSecondary = useFiltersExpansion(
    filters,
    {
      primary: '0',
      secondary: '2'
    },
    true
  );
  const oneSecondary = useFiltersExpansion(
    filters,
    {
      primary: '0',
      secondary: '1'
    },
    true
  );
  const noSecondaryVerified = {
    ...useFiltersExpansion(
      filters,
      {
        primary: '0',
        secondary: '0'
      },
      true
    )
  };
  const unverified = {
    ...useFiltersExpansion(
      filters,
      {
        primary: '0',
        secondary: '0'
      },
      true
    ),
    is_capability: true
  };

  const filterList = removeDuplicateFilters([
    allSecondary,
    someSecondary,
    oneSecondary,
    noSecondaryVerified,
    unverified
  ]);
  const resultDetails = [
    { verified: true, expanded: false },
    { verified: true, expanded: false },
    { verified: true, expanded: false },
    { verified: true, expanded: false },
    { verified: false, expanded: false }
  ];
  return {
    filterList,
    resultDetails
  };
}

// Generates a filters object to match at least n primary and n secondary specs,
// treating properties as secondary specs.
// Pass "all" instead of a number to match on all primary or secondary specs.
function useFiltersExpansion(filters, { primary, secondary }, isBales = false) {
  // const filters = useRelevantFiltersForForm() || DEFAULT_VALUE;
  const kpiFilters = filters.kpis || DEFAULT_VALUE;
  const propertyFilters = filters.properties || DEFAULT_VALUE;

  const primaryKpis = usePrimaryKpis();
  const secondaryKpis = useSecondaryKpis();

  const minPrimaryMatches = useMemo(
    () =>
      primary === 'all'
        ? primaryKpis.length
        : Math.min(Number(primary), primaryKpis.length),
    [primary, primaryKpis]
  );

  const minSecondaryMatches = useMemo(
    () =>
      secondary === 'all'
        ? secondaryKpis.length + Object.keys(propertyFilters).length
        : Math.min(
            Number(secondary),
            secondaryKpis.length + Object.keys(propertyFilters).length
          ),
    [secondary, secondaryKpis, propertyFilters]
  );

  return useMemo(() => {
    if (!filters || Object.keys(filters).length === 0) return null;
    return {
      ...(filters || {}),
      capacity__gte: minSecondaryMatches ? filters?.capacity__gte : undefined,
      kpis: undefined,
      properties: undefined,
      kpis_properties: {
        kpis: isBales
          ? {}
          : {
              ...primaryKpis.reduce((obj, kpiCode) => {
                obj[kpiCode] = {
                  ...kpiFilters[kpiCode],
                  allow_null: false,
                  or_group: primary !== undefined ? 'primary' : 'secondary' // if primary is not defined then treat everything as secondary
                };
                return obj;
              }, {}),

              ...secondaryKpis.reduce((obj, kpiCode) => {
                obj[kpiCode] = {
                  ...kpiFilters[kpiCode],
                  allow_null: false,
                  or_group: 'secondary'
                };
                return obj;
              }, {})
            },

        properties: Object.keys(propertyFilters).reduce((obj, propertyCode) => {
          obj[propertyCode] = {
            values: propertyFilters[propertyCode],
            or_group: 'secondary'
          };
          return obj;
        }, {}),
        or_groups: {
          primary: {
            min_match: minPrimaryMatches
          },
          secondary: {
            min_match: minSecondaryMatches
          }
        }
      },
      kpi_operator: 'and',
      property_operator: 'and'
    };
  }, [
    filters,
    kpiFilters,
    propertyFilters,
    primaryKpis,
    secondaryKpis,
    minPrimaryMatches,
    minSecondaryMatches
  ]);
}
