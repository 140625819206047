import React from 'react';
import { Button, Col, Flex, Row, Spin, Typography } from 'antd';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import useGetProjectContractTerm from 'src/Query/useGetProjectContractTerms';
import { useParams } from 'react-router-dom';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { REQUEST_STATUS } from 'src/constants/projectTdss';
import { ReactComponent as EmptyState } from 'src/components/icons/svg/EmptyState.svg';
import StatusPill from './StatusPill';
import TermCard from './TermCard';
import styles from './TermPanel.module.less';

function TermPanel() {
  const { projectTdsId } = useParams();
  const { data, isLoading } = useGetProjectContractTerm(projectTdsId);

  const projectTdssData = data?.results ? data.results[0] : {};
  const { contract_terms, project, tds } = projectTdssData;

  return (
    <ExplorePanel
      header={
        <Flex justify="space-between" align="middle">
          <Typography.Text className="lg heavy">Terms</Typography.Text>
          <StatusPill done={projectTdssData?.status === 3}>
            {projectTdssData?.status || 0} of 3 Complete
          </StatusPill>
        </Flex>
      }
      pad
      className="mb-lg procure--testing"
    >
      {isLoading || !projectTdssData?.status ? (
        <EmptyTerm />
      ) : (
        <Spin spinning={isLoading}>
          <Row gutter={[16, 0]}>
            <Col span={8}>
              <TermCard title="Shipment Details">
                <Typography className={styles.title}>Shipment</Typography>
                <Typography className={styles.content}>
                  {contract_terms?.shipment_count} shipments of{' '}
                  {prettyNumberRound(project?.capacity)}{' '}
                  {project?.capacity_units}
                </Typography>
                <Typography className={styles.title}>Cadence</Typography>
                <Typography className={styles.content}>
                  {`${contract_terms?.shipment_frequency ?? ''}, starting ${
                    contract_terms?.shipment_start_date ?? ''
                  }`}
                </Typography>
              </TermCard>
            </Col>
            <Col span={8}>
              <TermCard title="Quality Assurance Requests">
                <Typography className={styles.title}>Request</Typography>
                <Flex gap="small">
                  {(contract_terms?.qa_requests ?? []).map((request) => (
                    <StatusPill
                      done={request.status === REQUEST_STATUS.SUCCESS}
                      error={request.status === REQUEST_STATUS.FAILED}
                      key={request.id}
                    >
                      {request.name}
                    </StatusPill>
                  ))}
                </Flex>
                <Typography className={styles.title}>
                  Assurance Schedule
                </Typography>
                <Typography className={styles.content}>
                  {contract_terms?.assurance_schedule_days} days
                </Typography>
              </TermCard>
            </Col>
            <Col span={8}>
              <TermCard title="Pricing Benchmark">
                <Typography className={styles.title}>Index</Typography>
                <Typography className={styles.content}>
                  {tds?.price_index?.source_name ?? ''}
                </Typography>
                <Typography className={styles.title}>Change</Typography>
                <Typography className={styles.content}>
                  +/- {contract_terms?.variance ?? ''}%
                </Typography>
              </TermCard>
            </Col>
          </Row>
        </Spin>
      )}
    </ExplorePanel>
  );
}

export default TermPanel;

function EmptyTerm() {
  return (
    <Flex vertical justify="center" align="center" style={{ margin: 40 }}>
      <EmptyState style={{ height: 85 }} />
      <Typography.Text className="heavy mb-sm">
        No contract terms
      </Typography.Text>
      <Button size="small" type="primary">
        Add terms
      </Button>
    </Flex>
  );
}
