import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Radio, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

export default function CheckboxCard({
  title,
  complete,
  description,
  buttonText,
  buttonAction
}) {
  return (
    <div className="checkbox-card mb-md">
      <Flex justify="space-between">
        <Flex align="start" className="gap">
          {complete ? (
            <CheckCircleFilled className="checkbox-card--icon" />
          ) : (
            <Radio style={{ pointerEvents: 'none' }} />
          )}
          <div>
            <Typography className="weight-600 font-size-3a">{title}</Typography>
            <Typography.Text type="secondary" className="font-size-3">
              {description}
            </Typography.Text>
          </div>
        </Flex>
        <Button onClick={buttonAction} className="checkbox-card--review-button">
          {buttonText}
        </Button>
      </Flex>
    </div>
  );
}
CheckboxCard.propTypes = {
  title: PropTypes.string,
  complete: PropTypes.bool,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func
};
