import React, { useEffect } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Radio,
  Space,
  Row,
  Typography
} from 'antd';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

import FrequencySelect from 'src/components/form/FrequencySelect';
import IndexSelect from 'src/components/form/IndexSelect';
import { POSITIVE_NUMBER_RULE } from 'src/utils/rules';
import CircularInputNumber from 'src/components/form/CircularInputNumber';
import { DATA_TEST_ID_SHIPMENT_FREQUENCY } from 'src/constants/test-id/BuyerSetupTestingTestIds';
import { createOrUpdateContractTerm } from 'src/Mutation';
import ContractTermsIcon from 'src/components/icons/ContractTerms';
import { DATETIME_FORMAT } from 'src/constants';
import { numPeriods } from 'src/components/utils/shipment';
import useGetProjectSupplierContractTerms, {
  useInvalidateGetProjectSupplierContractTerms
} from 'src/Query/useGetProjectSupplierContractTerms';
import QuantityWithUnitsInput from 'src/components/form/QuantityWithUnitsInput';

import StickyHeader from 'src/components/layout/StickyHeader';
import { useInvalidateGetProjectContractTerms } from 'src/Query/useGetProjectContractTerms';
import styles from './ContractTerms.module.less';

export const CONTRACT_LENGTH_OPTIONS = {
  SHIPMENT_END_DATE: 1,
  NUMBER_OF_SHIPMENTS: 2
};

export const QUALITY_ASSURANCE_OPTIONS = {
  MATERIAL_PHOTO: 1,
  TESTING_RESULTS: 2
};

function ContractTermsSupplier({ project }) {
  const { projectTdsId, projectId } = useParams();
  const [form] = Form.useForm();
  const currentContractOption = Form.useWatch('contractOption', form);
  const navigate = useNavigate();

  const { data: existingProjectTdsContractTerms } =
    useGetProjectSupplierContractTerms(projectTdsId);
  const supplierName = existingProjectTdsContractTerms?.tds?.company?.name;
  // When existing contract terms load, update form
  useEffect(() => {
    if (existingProjectTdsContractTerms) {
      form.setFieldsValue({
        contractOption: CONTRACT_LENGTH_OPTIONS.NUMBER_OF_SHIPMENTS,
        // price_index_id,
        qualityAssurance:
          existingProjectTdsContractTerms.contract_terms.qa_requests?.map(
            ({ name }) =>
              name === 'photo'
                ? QUALITY_ASSURANCE_OPTIONS.MATERIAL_PHOTO
                : name === 'testing'
                ? QUALITY_ASSURANCE_OPTIONS.TESTING_RESULTS
                : null
          ),
        contract_terms: {
          ...existingProjectTdsContractTerms.contract_terms,
          market_price_benchmark_id:
            existingProjectTdsContractTerms.contract_terms
              ?.market_price_benchmark?.uuid,
          shipment_start_date: dayjs(
            existingProjectTdsContractTerms.contract_terms.shipment_start_date
          )
        }
      });
    }
  }, [existingProjectTdsContractTerms, form]);

  const invalidateParent = useInvalidateGetProjectContractTerms(projectId);
  const invalidateMe =
    useInvalidateGetProjectSupplierContractTerms(projectTdsId);
  const { mutateAsync: createContractTermData, isLoading } = useMutation({
    mutationFn: (data) => createOrUpdateContractTerm(data),
    onSuccess: () => {
      navigate('..', { relative: 'path' });
      invalidateParent();
      invalidateMe();
    }
  });

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const { contractOption, price_index_id, qualityAssurance, contract_terms } =
      values;

    const qa_requests = qualityAssurance.map((qa) => {
      if (qa === QUALITY_ASSURANCE_OPTIONS.MATERIAL_PHOTO) {
        return { name: 'photo' };
      }
      return { name: 'testing' };
    });

    const {
      shipment_end_date,
      shipment_start_date,
      shipment_frequency,
      ...rest
    } = contract_terms;
    const formattedContractTermData = {
      uuid: existingProjectTdsContractTerms?.uuid,
      project_tds_id: Number(projectTdsId),
      price_index_id,
      contract_terms: {
        ...rest,
        qa_requests,
        shipment_count:
          contractOption === CONTRACT_LENGTH_OPTIONS.SHIPMENT_END_DATE
            ? numPeriods(
                shipment_start_date,
                shipment_end_date,
                shipment_frequency
              )
            : contract_terms.shipment_count,
        shipment_start_date: shipment_start_date.format(DATETIME_FORMAT.DATE),
        shipment_frequency
      }
    };
    await createContractTermData(formattedContractTermData);
  };

  const handleCancel = () => {
    navigate('..', { relative: 'path' });
  };

  return (
    <>
      <StickyHeader height={48} style={{ margin: '0 -24px' }}>
        <Flex justify="space-between">
          <div>
            <ContractTermsIcon style={{ verticalAlign: '-0.4em' }} />{' '}
            <Typography.Text className="heavy poppins font-size-4 ml-xs">
              Request Contract Terms {supplierName ? `for ${supplierName}` : ''}
            </Typography.Text>
          </div>
          <div>
            <Space>
              <Button loading={isLoading} onClick={handleCancel}>
                Cancel
              </Button>
              <Button loading={isLoading} type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Space>
          </div>
        </Flex>
      </StickyHeader>
      <div className={styles.contractTermsSupplier}>
        <Typography.Title className="mt-lg" level={5}>
          Shipment Details
        </Typography.Title>
        <Form
          initialValues={{
            contractOption: CONTRACT_LENGTH_OPTIONS.SHIPMENT_END_DATE
          }}
          layout="vertical"
          className="mt-lg"
          form={form}
        >
          <Row gutter={[24, 0]}>
            <Col span={8}>
              <Form.Item
                name={['contract_terms', 'total_contract_volume']}
                label="Total Contract Volume"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Total Contract Volume'
                  },
                  POSITIVE_NUMBER_RULE
                ]}
              >
                <QuantityWithUnitsInput
                  unitsFieldName={[
                    'contract_terms',
                    'total_contract_volume_units'
                  ]}
                  className="w-full"
                />
              </Form.Item>
            </Col>
            <Col span={16} />
            <Col span={8}>
              <Form.Item
                name={['contract_terms', 'shipment_quantity']}
                label="Shipment Quantity"
                rules={[
                  { required: true, message: 'Please enter Shipment Quantity' },
                  POSITIVE_NUMBER_RULE
                ]}
              >
                <QuantityWithUnitsInput
                  unitsFieldName={['contract_terms', 'shipment_quantity_units']}
                  className="w-full"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={['contract_terms', 'shipment_start_date']}
                rules={[
                  {
                    required: true,
                    message: 'Please select First Shipment Date'
                  }
                ]}
                label="First Shipment Date"
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={['contract_terms', 'shipment_frequency']}
                rules={[
                  { required: true, message: 'Please select Shipping Cadence' }
                ]}
                label="Shipping Cadence"
              >
                <FrequencySelect
                  data-testid={DATA_TEST_ID_SHIPMENT_FREQUENCY}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="contractOption"
            className="mb-xs"
            label="Contract Length"
            rules={[{ required: true }]}
          >
            <Radio.Group size="small">
              <Radio value={CONTRACT_LENGTH_OPTIONS.SHIPMENT_END_DATE}>
                Shipment End Date
              </Radio>
              <Radio value={CONTRACT_LENGTH_OPTIONS.NUMBER_OF_SHIPMENTS}>
                Number of Shipments
              </Radio>
            </Radio.Group>
          </Form.Item>
          {currentContractOption ===
          CONTRACT_LENGTH_OPTIONS.SHIPMENT_END_DATE ? (
            <Form.Item
              rules={[
                { required: true, message: 'Please select Shipment End Date' }
              ]}
              name={['contract_terms', 'shipment_end_date']}
            >
              <DatePicker className={styles.datePicker} />
            </Form.Item>
          ) : (
            <Form.Item
              name={['contract_terms', 'shipment_count']}
              rules={[
                { required: true, message: 'Please enter Number of Shipments' },
                POSITIVE_NUMBER_RULE
              ]}
            >
              <CircularInputNumber className={styles.inputShipment} />
            </Form.Item>
          )}
          <Typography.Title
            className={classNames(styles.shipmentTitle, 'mt-xxl')}
            level={5}
          >
            Quality Assurance Requests
          </Typography.Title>
          <Form.Item
            name="qualityAssurance"
            className="mt-lg"
            label="Quality Assurance"
            rules={[{ required: true }]}
          >
            <Checkbox.Group>
              <Checkbox value={QUALITY_ASSURANCE_OPTIONS.MATERIAL_PHOTO}>
                Require Material Photo
              </Checkbox>
              <Checkbox value={QUALITY_ASSURANCE_OPTIONS.TESTING_RESULTS}>
                Require Testings Results
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name={['contract_terms', 'assurance_schedule_days']}
            label="Assurance Due Ahead of Shipment"
            rules={[
              {
                required: true,
                message: 'Please enter Assurance Due Ahead of Shipment'
              },
              POSITIVE_NUMBER_RULE
            ]}
          >
            <CircularInputNumber addonAfter="Days" />
          </Form.Item>
          {/* <Form.Item className="mb" label="Circular & Supplier Agreement">
            <Typography.Text type="secondary">
              Review the contract that suppliers have with Circular so you know
              what to expect
            </Typography.Text>
          </Form.Item>
          <Button>Download</Button> */}
          <Typography.Title
            className={classNames(styles.shipmentTitle, 'mt-xxl')}
            level={5}
          >
            Pricing Benchmarks
          </Typography.Title>
          <Row className="mt-lg" gutter={[24, 0]}>
            <Col span={16}>
              <Form.Item
                name={['contract_terms', 'market_price_benchmark_id']}
                rules={[{ required: false }]}
                label="Index"
              >
                <IndexSelect allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  // Check if 'market_price_benchmark_id' has a value.
                  if (
                    !getFieldValue([
                      'contract_terms',
                      'market_price_benchmark_id'
                    ]) ||
                    value
                  ) {
                    // Either market_price_benchmark_id is empty (so variance isn’t required) or variance is filled.
                    return Promise.resolve();
                  }
                  // Otherwise, if fieldA has a value and fieldB is empty, throw an error.
                  return Promise.reject(
                    new Error('Movement is required if an Index is specified')
                  );
                }
              }),
              POSITIVE_NUMBER_RULE
            ]}
            name={['contract_terms', 'market_price_variance']}
            label="Movement"
          >
            <CircularInputNumber addonAfter="%" />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default ContractTermsSupplier;
