import { Col, message, Row, Typography } from 'antd';
import React from 'react';
import { captureException } from '@sentry/react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import ExploreSection from 'src/components/project/explore/ExploreSection';
import SupplierCapabilityBarChart from 'src/components/project/explore/charts/SupplierCapabilityBarChart';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import MarketPriceByFormLineChartWithControls from 'src/components/analytics/MarketPriceByFormLineChartWithControls';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProject } from 'src/Mutation';

const EMPTY_OPTIONS = {};

export default function Pricing() {
  const filters = useRelevantFiltersForForm() || EMPTY_OPTIONS;
  const [projectSettings] = useConciergeContextState([
    'explore',
    'projectSettings'
  ]);
  const { activeProjectId, activeProject } = useSidebarContext();
  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = message.useMessage();
  const { mutate: updateProjectMutation, isLoading: updating } = useMutation({
    mutationFn: updateProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
    },
    onError: (e) => {
      messageApi.error('Error updating project. Please try again.', 8);
      // Send error to Sentry
      captureException(e);
    }
  });

  const onPriceSettingsChange = (settings) => {
    if (activeProject) {
      updateProjectMutation({
        uuid: activeProjectId,
        settings: {
          ...activeProject.settings,
          prices: settings
        }
      });
    }
  };

  return (
    <ExploreSection className="pricing-section">
      {contextHolder}
      <Typography.Paragraph strong className="font-size-4 mb-lmd">
        Pricing History
      </Typography.Paragraph>
      <Row gutter={16} wrap={false}>
        <Col span={14}>
          <ExplorePanel className="price-history-chart__panel" pad>
            <MarketPriceByFormLineChartWithControls
              filters={filters}
              priceSettings={projectSettings?.prices}
              onPriceSettingsChange={onPriceSettingsChange}
            />
          </ExplorePanel>
        </Col>
        <Col span={10}>
          <SupplierCapabilityBarChart />
        </Col>
      </Row>
    </ExploreSection>
  );
}
