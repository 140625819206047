import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

// must match models/shared.py Frequency
const options = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'semi_annually', label: 'Semi annually' },
  { value: 'annualy', label: 'Annualy' }
];

function FrequencySelect({ value, onChange, ...rest }) {
  return (
    <Select value={value} onChange={onChange} {...rest}>
      {options.map((option) => (
        <Select.Option value={option.value} key={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
}

FrequencySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default FrequencySelect;
